body {
  color: #000;
  font-size: 16px;
}
.header-top {
  background: #0d539a;
}
.product {
  //background: url("/images/bg2.png") center;
  .section-intro {
    position: relative;
    z-index: 1;
  }
}
.header {
  &__wrap {
    display: flex;
    align-items: center;
  }

  &__inner {
    margin-left: 15px;
    line-height: 1.2;

    div {
      &:first-child {
        font-size: 14px;
      }

      &:last-child {
        text-transform: uppercase;
        font-size: 20px;
      }
    }
  }
}
.footer {
  &__inner {
    margin-left: 15px;
    line-height: 1.2;

    div {
      color: #000000;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}
.header-top__social {
  display: none !important;
}

.header_area.navbar_fixed .main_menu {
  background: #ffffff !important;
}

.container_my {
  max-width: 1290px;
}
.partner {
  &__item {
    margin: 15px 0;
    &-img {
      max-width: 150px;
      margin-right: 30px;
    }
  }
}
.section-intro__title {
  margin-bottom: 25px;
}
.contacts {
  a,p {
    color: #000000;
  }
}
@media (min-width: 1000px) {
  .section-padding--small {
    padding: 60px 0 40px;
  }
}

footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1290px;
  padding: 15px 0;
  a {
    color: #000000 !important;

    &:hover {
      color: #000000;
    }
  }
}

.header_area .navbar .nav .nav-item .nav-link {
  text-transform: inherit !important;
}

.header_area .navbar .nav .nav-item:hover .nav-link, .header_area .navbar .nav .nav-item.active .nav-link {
  color: #0d539a !important;
}

.button {
  background-color: #0d539a !important;
  color: #fff;
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item:hover .nav-link, .header_area.navbar_fixed .main_menu .navbar .nav .nav-item.active .nav-link {
  color: #0d539a !important;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
  color: #0d539a !important;
}
.cta__content {
  max-width: 100%;
  p {
    text-align: justify;
    text-indent: 35px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .navbar-toggler span {
    background: #1b4f97;
  }
  .navbar-toggler {
    margin-right: 15px;
  }
}
.cta-wrapper {
  background: none;
  p {
    color: #000000;
  }
  &:after {
    background-color: #f7f7f7;
  }
}


.caption {
  font-size: 16px;
}
.pb-65px {
  padding-bottom: 30px;
}
.about {
  font-size: 40px;
  margin-bottom: 30px;
  &__text {
    text-align: justify;
    font-size: 16px;
    text-indent: 35px;
    margin-bottom: 5px;
  }
  &__button {
    margin-bottom: 30px;
    border: none;
    background: none;
    position: relative;
    padding-left: 30px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 30px;
      height: 15px;
      background: url("/images/back.svg") center no-repeat;
    }
  }
}
.testimonial .owl-dots .owl-dot.active span {
  background-color: #0d539a!important;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
  background-color: #0d539a!important;
  color: #fff !important;
}
.testimonial {
  &__img {
    max-width: 100%;
    height: 180px;
    margin: 15px auto 0;
    &.-two_img {
      display: flex;
      justify-content: center;
      img {
        width: 20% !important;
        min-width: 130px !important;
      }
    }
  }
  &__item {
    h3 {
      text-align: center;
    }
  }
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.testimonial__icon {
  display: none;
}
.activities {
  padding: 50px 0 30px;
}
@media (min-width: 600px) {
  .testimonial .owl-dots {
    margin-top: 0 !important;
  }
}
@media (max-width: 991px) {
  .activities {
    padding: 30px 0 20px;
  }
  .about {
    font-size: 26px;
    margin-bottom: 20px;
    &__text {
      font-size: 16px;
    }
  }
  .header_area .navbar {
    background: #ffffff !important;
  }
  .header_area .navbar .nav .nav-item {
    padding: 0 15px;
  }
  .header_area .navbar-brand {
    margin: 0;
    padding: 0 10px;
  }
}

@media (min-width: 1000px) {
  .section-padding--smaller {
    padding: 40px 0 15px
  }
  .cta__content p {
    margin-bottom: 15px;
  }
}
@media (min-width: 600px) {
  .testimonial__item {
    padding: 30px 25px;
  }
}
@media (max-width: 575px) {
  .header__inner div:first-child {
    font-size: 12px;
  }
  .navbar-toggler {
    margin: 20px 15px 5px;
  }
}